import React from "react";
import "./style.scss";
import { useEffect, useState } from "react";

export default function Content() {
  const [data, setData] = useState();
  const [key, setKey] = useState("");
  const [keyInput, setKeyInput] = useState("");
  const [isOpen, setIsOpen] = useState("");

  useEffect(() => {
    fetch("https://62abd0e7bd0e5d29af15943d.mockapi.io/user")
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <div className="content">
      <div className="search">
        <input
          type="text"
          placeholder="id"
          onKeyUp={(event) => setKeyInput(event.target.value)}
        />
        <button
          onClick={() => {
            if (
              keyInput.toUpperCase() == "1" ||
              keyInput.toUpperCase() == "QT02SXY"
            ) {
              setKey(keyInput.toUpperCase());
            }

            if (
              keyInput.toUpperCase() == "1" ||
              keyInput.toUpperCase() == "QT02SXY"
            ) {
              setIsOpen("1");
            }
          }}
        >
          Search
        </button>
      </div>
      <div className="container">
        {data
          ?.filter((item) => item?.id == key)
          .map((item, index) => {
            return (
              <div key={index} className="card">
                <div className="imgCard">
                  <div className="avatar">
                    <img src={item?.avatar} alt="pic" width={100} />
                  </div>
                  <div className="name">{item.name}</div>
                  <span>{item.slugs}</span>
                  <div className="mn">
                    <div className="ctn">
                      <span>Tiền tồn:</span>
                      <div className="moneyItem">
                        {item.money.tienTon.toLocaleString()}
                      </div>
                    </div>
                    <div className="ctn">
                      <span>Tổng tiền:</span>
                      <div className="moneyItem">
                        {item.money.tongTien.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="moneyCard">
                  <div className="wr">
                    <span>Tiền đã nhận:</span>
                    <div className="moneyItem">
                      {item.money.tienDaNhan.toLocaleString()}
                    </div>
                  </div>
                  <div className="wr">
                    <span>Tiền thưởng:</span>
                    <div className="moneyItem">
                      {item.money.tienThuong.toLocaleString()}
                    </div>
                  </div>
                  <div className="wr">
                    <span>Tiền có thể nhận:</span>
                    <div className="moneyItem">
                      {item.money.tienCoTheNhan.toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {isOpen ? (
          <div className="card">
            <div className="job">
              <div className="jobItem">
                <ul>
                  <h3>List Job</h3>
                  <li>
                    <span>Chụp ảnh 50 ảnh:</span>
                    <span>200,000</span>
                  </li>
                  <li>
                    <span>Chụp ảnh 200 ảnh:</span>
                    <span>1,000,000</span>
                  </li>
                  <li>
                    <span>Quay clip (1p):</span>
                    <span>200,000</span>
                  </li>
                  <li>
                    <span>Quay clip (5p):</span>
                    <span>1,200,000</span>
                  </li>
                  <li>
                    <span>Quay clip thủ dâm (1p):</span>
                    <span>1,000,000</span>
                  </li>
                  <li>
                    <span>Quay clip thủ dâm (10p):</span>
                    <span>5,000,000</span>
                  </li>
                  <li>
                    <span>Quay clip thủ dâm xuất:</span>
                    <span>7,000,000</span>
                  </li>
                  <li>
                    <span>Sex giả:</span>
                    <span>8,000,000</span>
                  </li>

                  <li>
                    <span>Sex thật:</span>
                    <span>10,000,000</span>
                  </li>
                  <li>
                    <span>Sex lần đầu:</span>
                    <span>30,000,000</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
